// Here you can add other styles
.spin-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  position: fixed; /* Ensure it stays centered on page load */
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Light overlay */
  z-index: 1000; /* Make sure it appears above other content */
}
