.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa; /* Example background color */
  padding: 10px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensures it's above other elements */
}

.cookie-main {
  pointer-events: none;
  opacity: 0.5;
  // background-color:grey;
  // color: grey
  // background-color: rgba(58, 42, 45, 0.95);
}
